export default {
  welcome: "Welcome",
  hotels: "Hotels",
  resorts: "Resorts",
  place: "Place",
  places: "Places",
  liked: "Liked",
  comments: "Comments",
  save_to_read_list: "Save to reading list",
  reviews: "Reviews",
  share: "Share",
  close: "Close",
  next: "Next",
  prev: "Previous",
  report: "Report Abuse",
  comment: {
    report: "Report Abuse",
    form_title: "Share Your Impressions about",
    edit_title: "Edit comment",
    delete_title: "Delete comment",
    delete_prompt:
      "Are you sure you want to delete this comment? You cannot undo this action.",
    report_msg:
      "Please provide any additional information or context that will help us understand and handle the situation.",
  },
  edit: "Edit",
  reply: "Reply",
  delete: "Delete",
  submit: "Submit",
  cancel: "Cancel",
  message: "Message",
  what_before: "What to know before visiting {place}",
  humidity: "humidity",
  temperature: "temperature",
  km_away: "{distance} km away",
  m_away: "{distance} meter away",
  all_stay: "All Stays",
  hotel: "hotel",
  resort: "resort",
  home: "Home",
  contact_us: "Contact Us",
  contact_des: "Send us message and we will get back for you",
  form: {
    fullname: "Full name",
    number_phone: "Number Phone",
    phone: "Phone",
    subject: "Subject",
    message: "Message",
    send: "Send Message",
    thank: "Thank you for contacting us",
  },
  address: "Address",
  best_of_attraction: "Best Attractions of Vietnam",
  travel_to: "Travel to {place}",
  archived: "Archived",
  dashboard: "Dashboard",
  profile: "Profile",
  sign_out: "Sign Out",
  sign_in: "Sign In",
  see_details: "See details",
  remove: "Remove",
  privacy: "Privacy policy",
  terms_of_conditions: "Terms of Conditions",
  filter: {
    recent: "Most Recent",
    viewed: "Most Viewed",
    comments: "Most Comments",
    Descending: "Descending",
    Ascending: "Ascending",
  },
  discover_other_topics: "Discover other topics",
  discover_other_tags: "Discover other tags",
  articles: "Articles",
  min_read: "{min} min read",
  meta: {
    blog: {
      title: "Conquering Amazing Destinations in Vietnam",
      description:
        "Embark on an adventure through Vietnam's incredible destinations, each offering unique experiences and breathtaking sights.",
      keywords:
        "Vietnam travel,Exotic destinations,Adventure in Vietnam,Unique experiences,Breathtaking sights,Vietnam exploration,Must-visit places,Hidden gems in Vietnam,Vietnam tourist spots,Unforgettable journeys",
    },
    place: {
      thumbnail: "Discover Vietnam's Must-Visit Destinations",
      excerpt:
        "Embark on a captivating adventure through Vietnam's rich tapestry of destinations that promise a symphony of culture, history, and natural beauty. Our carefully curated list unveils the essence of Vietnam, from the bustling streets of vibrant cities to serene landscapes that whisper tales of tradition",
      title: "Your Guide to Essential Destinations",
      description:
        "Immerse yourself in the enchanting beauty of Vietnam as we unveil a curated list of must-visit destinations",
      keywords:
        "Vietnam travel,Top destinations,Cultural landmarks,Hidden gems,Must-visit places,Vietnamese attractions,Exotic travel experiences,Explore Vietnam,Unforgettable journeys,Natural wonders of Vietnam",
    },
    attraction: {
      thumbnail: "Explore Vietnam's Top Tourist Attractions",
      excerpt:
        "Delve into the heart of Vietnam with our meticulously crafted list of top tourist attractions. Each entry is a gateway to a unique story, from the ancient tales etched in historic landmarks to the breathtaking landscapes that showcase the country's natural wonders.",
      title: "Discover Cultural and Natural Wonders of Vietnam",
      description:
        "Discover a curated list of unique tourist attractions and breathtaking landscapes in Vietnam. Experience the beauty of nature, culture, and history in this vibrant country.",
      keywords:
        "Vietnam attractions, tourist spots, natural wonders, cultural landmarks, must-visit places, travel destinations, Vietnam tourism, historic sites, scenic beauty",
    },
    author: {
      title: "Fantastic Travel Articles from {author}",
      description:
        "Share wonderful travel journeys with {author}. Explore beautiful destinations and listen to fascinating stories from those who have embarked on memorable adventures.",
    },
    tag_title: "Explore All Posts Tagged with {tag}",
    home: {
      title: "Discover Amazing Travel Destinations in Vietnam",
      description:
        "Explore Vietnam's wonders with AttractionsVietnam. From stunning beaches to bustling markets, experience the essence of this beautiful country.",
      keywords:
        "Vietnam travel, travel destinations, Vietnam hotels, Vietnam resorts, unique travel spots, share travel experiences",
    },
    sign_in: {
      title: "Sign In - AttractionsVietnam",
      description:
        "Sign in to AttractionsVietnam to experience amazing travel destinations in Vietnam. Manage your profile and share your experiences.",
    },
    sign_up: {
      title: "Join with Us - AttractionsVietnam",
      description:
        "Join AttractionsVietnam to access unique features. Create your account, manage your personal information, and become part of our vibrant travel community.",
    },
    contact: {
      title: "Contact Us - Attractions Vietnam",
      description:
        "We are here to assist you with any inquiries and requests related to travel and hotels in Vietnam. Let us help you create a wonderful and comfortable travel experience.",
    },
    hotels: {
      thumbnail: "Hotel Collection",
      title: "Hotel Collection - Discover Luxury and Uniqueness",
      description:
        "Step into the luxurious and unique world of hotels in Vietnam. This curated alphabetical list promises an unforgettable experience at these top-notch destinations.",
      keywords:
        "Vietnam hotels, Luxury experience, Unique hotel list, Hotel booking, Travel Vietnam",
    },
    resorts: {
      thumbnail: "Resort Collection",
      title: "Resort Collection - Discover Luxury Resort and Wellness Spa",
      description:
        "Explore the list of famous resorts in Vietnam, a place where luxury and class converge, giving you an unlimited vacation experience.",
      keywords:
        "Vietnam resorts, Resort list, Luxury vacations, Resort booking, Travel getaway",
    },
  },
  type_places: {
    commute: "Commute",
    tourist_attraction: "Tourist attraction",
    restaurant: "Restaurant",
    cafe: "Cafe",
    parking: "Parking",
    bus: "Bus Station",
    park: "Park",
    shopping: "Shopping",
    hospital: "Hospital",
  },
  view_all: "View All",
  topic: "Topic",
  insert: "Insert",
  upload: "Upload",
  upload_image: "Upload Image",
  upload_file: "Upload File",
  image: "Image",
  article_title: "Article title",
  article_excerpt: "Article excerpt",
  article_content: "Article content",
  category: "Category",
  tags: "Tags",
  featured_image: "Featured image",
  validate: {
    character_long: "Content must be at least {length} characters long",
    thumbnail: "Please upload a featured image for your article.",
  },
  next_step: "Next Step",
  author: "Author",
  sign_up: "Sign Up",
  or: "OR",
  already_account: "Already have an account?",
  forgot_password: "Forgot Password",
  forgot_your_password: "Forgot your password",
  dont_have_account: "Don’t have an account?",
  continue: "Continue",
  email_address: "Email address",
  go_back_for: "Go back for",
  term_auth:
    "By registering or logging in, you understand and agree to our Terms of Conditions and Privacy Policy.",
  what_looking: "What are you looking for?",
  attractions: "Attractions",
  language: "Language",
  dark_mode: "Dark mode",
  explore: "Explore",
  homepage: {
    top_attractions: "Top trending destinations",
    discover_attractions: "Discover over 100+ destinations",
    new_stays: "Looking for the perfect stay?",
    discover_stays: "Travellers with similar searches booked these properties",
    recent_articles: "Recent articles",
    discover_articles:
      "Get the most recent updates and insights on destinations, hotels, resorts, and attractions in Vietnam.",
    join_newsletter: "Join our newsletter",
    read_and_share: "Sign up and get the best travel deals!",
    sign_1: "Discover and sign up for the best travel deals across Vietnam",
    sign_2: "Detailed guide for travel to Vietnam",
    register_discount: "Sign up to receive our newsletter and promotions",
    please_read: "Please read ",
    enter_email: "Enter your email",
    hero_title: "Best travel deals from everywhere",
    hero_des: " Try searching for a city, a specific hotel, or even a landmark",
  },
  other_topics: "Other Topics",
  other_tags: "Other Tags",
  k_hotels: "Hotel",
  k_resorts: "Resort",
  trending_topic: "Trending topic",
  popular_articles: "Popular Articles",
  more_tags: "More Tags",
  more_tag: "More Tags",
  notepage_des:
    "Research weather, respect local customs, pack essentials, and embrace new flavors for a fulfilling travel experience.",
  note: "Note",
  locale_weather: "Locale weather",
  show_all_photos: "Show all photos",
  what_to_know: "What to know ?",
  destinations_near: "Destinations near {place}",
  destinations_blissfull:
    "Discover Blissful Destinations Near {place} for a Relaxing Getaway",
  see_all_destinations: "See all places",
  popular_stays_in: "Popular stays in {place}",
  discover_offers:
    "Discover offers, travel recommendations, and activities in {place}",
  explore_all: "Explore all places",
  search: "Search",
  socials: "Socials",
  guest_reviews: "Guest Reviews",
  posted_on: "Posted on",
  facilities_of: "Facilities - {hotel}",
  see_less_amenities: "See less amenities",
  see_more_amenities: "See more amenities",
  location_nearby: "Location & nearby places",
  nearby_commit:
    "Data provided is for reference purposes only. For more detailed information, please consult",
  add_discussion: "Add to discussion",
  add_reply: "Add Reply",
  all_rights_reserved: " All Rights Reserved",
  legal_notice: "Legal Notice",
  guest_about_place: "What guests said about hotels in {place}",
  cheap_price_place: "Cheapest time to stay in {place}",
  price_place_description:
    " In {months} you can stay in {place} for an average price of <b>US${price}</b> per night",
  faq_hotel: "FAQs about hotels in {place}",
  month: {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December",
  },
  score_brokendown: {
    staff: "Staff",
    services: "Services",
    clean: "Clean",
    comfort: "Comfort",
    value: "Value",
    location: "Location",
    breakfast: "Breakfast",
    free_wifi: "Free wifi",
    paid_wifi: "Paid wifi",
  },
  room_guests: "{guests} guests maximum, of which {persons} adults maximum.",
  room_type: "Room type",
  number_guests: " Number of guests",
  guests: "Guests",
  show_details: "Show details",
  showing: "Showing",
  next_page: "Next page",
  prev_page: "Previous page",
  traveller_type: "Traveller type",
  verified_reviews: "verified reviews",
  book_now: "Book Now",
  attraction_in: "Attractions in {place}",
  visitor_reviews: "Traveler Reviews",
  write_review: "Write a review",
  view_all_review: "View all reviews",
  showing_of: "Showing {reviews} of {total}",
  see_all_attractions: "See all attractions",
  day: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
  night: "night",
  nights: "nights",
  night_in: "in",
  calendar: "Calendar",
  i_am_flexible: " I'm flexible",
  how_long_stay: "How long do you want to stay?",
  when_go: "When do you want to go?",
  from: "From",
  select_up_month: "Select up to {month} months",
  select_dates: "Select dates",
  adults: "adults",
  children: "children",
  rooms: "rooms",
  age_message:
    "To find you a place to stay that fits your entire group along with correct prices, we need to know how old your child will be at check-out",
  done: "Done",
  title_check:
    "Please select check-in/check-out dates to see rates and availability",
  faq_about: "FAQs about {hotel}",
  start_from: "Start from ",
  flex: {
    week: "A week",
    weekend: "A weekend",
    month: "A month",
    other: "Other",
  },
  change_password: "Change Password",
  new_password: "New password",
  ideas_accom: "Ideas for Your Accommodation",
  view_more: "View more",
  nearby_attractions: "Nearby Attractions",
  photos: "Photos",
  search_hotel_around: "Search for hotels around here",
  by_sign_agree: "By signing in, you agree to our",
  submit_post: "Submit post",
  list_articles: "List Articles",
  description_dashboard:
    "View your dashboard, manage your posts, subscription and edit and profile.",
  view: "View",
  edit_profile: "Edit profile",
  firstname: "First name",
  lastname: "Last name",
  current_password: "Current password",
  username: "Username",
  social: "Social",
  or_drag: "or drag and drop",
  up_to: "up to",
  update: "Update",
  profile_updated_msg: "Profile successfully updated",
  choose_a_username: "Choose a username",
  user_msg: "A username helps you interact and be remembered. Choose one now!",
  name: "Name",
  action: "Action",
  my_account: "My Account",
  my_post: "My Post",
  dark_theme: "Dark theme",
  logout: "Logout",
  status: "Status",
  inappropriate: "Inappropriate",
  violence: "Violence",
  spam: "Spam",
  other: "Other",
  topic_des:
    "Choose a topic that suits your interests and start your journey of discovery",
  post_popular: " Popular Articles",
  post_des: "Let us guide you to the ideal destinations for your adventure",
  role: "Role",
  pending: "pending",
  published: "published",
  where_do_you_go: "Where do you go ?",
  recent_place_popular: "Recently popular destination",
  business_traveller: " Business travellers",
  couple: " Couples",
  family_with_children: " Families",
  solo_traveller: " Solo travellers",
  review_category_group_of_friends: "Groups of friends",
  order_first: "Oldest first",
  newest_first: "Newest first",
  featuredreviews: "Featured reviews",
  lowest_scores: "Lowest scores",
  highest_score: "Highest scores",
  no_yet_review: "No reviews yet",
  all_travel: "All travellers",
  room_facilities: "Room Facilities",
  exact_dates: "Exact dates",
  dayx: "day",
  days: "days",
  show_me_more: "Show more",
  author_loaded_full: "The author's posts have all been loaded.",
  copy_link: "Copy link",
  report_this_pge: "Report this page",
  your_password: "Your password",
  thing_to_do: "Things to do",
  age_needed: "Age needed",
  year_old: "years old",
  discover_tour: "Discover Tours",
  nearby: {
    nearby: "What's nearby",
    dining: "Restaurants & cafes",
    publicTransport: "Public transport",
    airports: "Closest Airports",
    beaches: "Beaches in the Neighborhood",
    top: "Top attractions",
    naturalBeauty: "Natural Beauty",
  },
  cafeBars: "Cafe Bars",
  restaurants: "Restaurants",
  mountain: "Mountain",
  river: "River",
  train: "Train",
  bus: "Bus",
  leave_your_comment: "Leave Your Comment",
  show_map: "Show map",
  translate_to: "Translate to {lang}",
  view_original: "View original",
  related_articles: "Related articles",
  reserve_now: "Reserve Now",
  about_us: "About Us",
  about_us_des:
    "Explore Vietnam Travel with AttractionsVietnam - Unique travel platform!",
  other_entity_near: "Other {type} nearby ",
  tourist_attraction_near: "Tourist attractions nearby",
  house_rules: "House Rules of",
  cancelled_payment: "Cancellation/ prepayment",
  age_restrict: "Age restriction",
  accept_payment: "Accepted payment methods",
  navigation: {
    overview: "Overview",
    rooms: "Rooms",
    location: "Location",
    facilities: "Facilities",
    house_rules: "House rules",
    reviews: "Reviews",
    details: "Details",
  },
  check_now: "Check now",
  view_facilities: "View More Facilities",
  best_hotel: "Best Hotels",
  best_resort: "Best Resorts",
  best_attrations: "Famous Tourist Attractions",
  suggest_hotel: "Recommended Accommodations",
  star: "Star",
  distance_legit:
    "All distances are measured in straight lines. Actual travel distance may vary",
  offer: {
    h2: " Promotions and special offers for you",
    title: "Plan your lengthiest getaway yet and explore",
    heading:
      "Discover properties offering extended stays, with many featuring reduced monthly rates.",
    button: "Find a stay",
  },
  smoking: "Smoking",
  see_all: "See all properties in",
  check_availibility: "Check availability",
  form_searching: {
    location: {
      placeholder: "Enter destination or property",
      des: "Where are you going?",
    },
    guest: {
      des: "Guests and rooms",
    },
    checkbox: " I'm traveling for work",
    checkbox_tooltips:
      "<span class='block mb-2'>If you select this option, we'll show you popular businesstravel</span> <span class='block'>features like breakfast, Wifi and free parking.</span>",
  },
  view_deal: "View deal",
  free_cancel: "Free cancellation",
  no_prepayment: "No prepayment",
  soldout_msg: "We did not have any rooms for your search",
  recent_search: "Your recent searches",
  popular_des: "Popular destinations nearby",

  sort: {
    popularity: "Our top picks",
    upsort_bh: "Homes and apartments first",
    price: "Price (lower first)",
    bayesian_review_score: "Top reviewed",
    review_score_and_price: "Best reviewed & lowest price",
    class: "Property rating (high to low)",
    class_asc: "Property rating (low to high)",
    class_and_price: "Property rating and price",
    distance_from_search: "Distance from downtown",
  },
  show_price: "Show price",
  room_title: "Choose dates to view prices",
  room_options: "Room options",
  more_details: "More details",
  overall_rating: "Overall rating is based on reviews from our partners",
  guest_rating_location: "Guests rated this property for Location",
  view_on_map: "View on map",
  attractions_in: "Attractions in ",
  where: "Where",
  when: "When",
  location: "Location",
  currency: "Currency",
  checkin: "Check in",
  checkout: "Check out",
  properties: "{count}  properties in {place}",
  great_deal: "Great deal",
  redirect_des:
    " We are taking you to the partner's website to finalise your booking.",
  redirect_msg: "If you are not redirected automatically, please ",
  click_here: "click here",
  where_to: "Where to",
  start_trip: "Your Trip Starts Here",
  clear_all: "Clear All",
  sort_by: "Sort by",
  filter_: "Filter",
  sort_: "Sort",
  who: "Who",
  when_trip: " When's your trip",
  reset_filters: "Reset Filters",
  finding: "Finding",
  oops_search: "Oops, we're a little lost.",
  oops_des:
    " We can't find any properties, but no worries—discover unbeatable deals with our service!",
  region: "Region of {place}",
  region_hotel: "Hotels in {place}",
  go_to_homepage: "Go to home",
  place_hotel: {
    title: "Find the best Hotels in {place} - Find the Best Accommodations",
    description:
      "Explore and book the best hotels in Ho Chi Minh City. From luxurious 5-star hotels to affordable stays, find the perfect accommodation for your visit to Saigon. Enjoy the best rates and top amenities.",
  },
  our_partner: "Our Partners",
  subscribe: "Subscribe",
  subscribe_msg: "Subscribe and we’ll send the best deals to you",
  partner_msg:
    "Travel services and products on this website are provided by our Partners",
  toc: "Table of Content",
  sub_blog_msg:
    "Get notified when I publish something new, and unsubscribe at any time.",
  show_less: "Show less",
  show_more: "Show {count} more",
  flight_tour: "Flights & Tours",
  destinations:"Destinations",
  display_more:"Show { total } results",
  help:"Help"
};
